import * as React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { motion } from 'framer-motion'
import {
  PartialContainer,
  Logo,
  Social,
  Button,
  DisclosureContent,
} from 'components'
import bull from '../../assets/bull.png'
import Styles from './footer.module.css'
import axios from 'axios'
import { PuffLoader } from 'react-spinners'
import { CheckIcon } from '@heroicons/react/24/outline'
import { AuthContext } from 'context/auth'

const Newsletter = () => {
  const [email, setEmail] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsLoading(true)

    axios
      .post(process.env.NEXT_PUBLIC_API_URL + '/users', {
        email,
      })
      .then(response => {
        setEmail('')
        setIsLoading(false)
        setIsEmailSent(true)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <div className="mt-12 max-w-md">
          <PuffLoader size={22} color={'white'} />
        </div>
      ) : isEmailSent ? (
        <div className="mt-12 flex max-w-md items-center text-white">
          <CheckIcon className="mr-2 h-4 w-4" />
          <p className="py-2 text-center font-body text-base font-normal">
            You are subscribed!
          </p>
        </div>
      ) : (
        <form
          className="mt-12 w-full max-w-md font-body"
          onSubmit={handleSubmit}>
          <label
            htmlFor="newsletter-email"
            className="mb-3 block text-2xl text-white">
            Subscribe to our newsletter
          </label>
          <div
            className={`${Styles.newsletterGradient} flex flex-col rounded-lg p-0.5 text-lg sm:flex-row`}>
            <input
              id="newsletter-email"
              type="email"
              name="email"
              required
              onChange={(e: any) => {
                setEmail(e.target.value)
              }}
              placeholder="Enter your email here."
              className="w-full rounded-md pb-5 pl-5 pr-7 pt-[14px] outline-none sm:pb-[14px]"
            />
            <Button
              newButton
              className="-mt-2 sm:-ml-3 sm:mt-0"
              newButtonSize="small">
              Subscribe
            </Button>
          </div>
        </form>
      )}
    </>
  )
}

const FOOTER_LINKS = [
  [
    {
      title: 'About us',
      href: 'https://web.marketxventures.com/about',
    },
    {
      title: 'Invest',
      href: '/invest/companies',
    },
    {
      title: 'Funds',
      href: '/managedfund',
    },
    {
      title: 'Shareholders',
      href: '/list-your-shares',
    },
    {
      title: 'Privacy Policy',
      href: 'https://docs.google.com/document/u/1/d/e/2PACX-1vRn7Fe07NFLLy6eo84S0Y7N0eBbgg3n8DPnzFaRlDfwuHmZriSSj0kThBEiEz8UWL_7yMvlPH248TMf/pub',
    },
  ],
  [
    {
      title: 'Events',
      href: '/events',
    },
    {
      title: 'Careers',
      href: 'https://www.linkedin.com/jobs/marketx-ventures-jobs-worldwide?f_C=6437444&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0',
    },
    {
      title: 'Disclosures',
      href: '/disclosure',
    },
    {
      title: 'Investment Webinars',
      href: 'https://www.youtube.com/channel/UCizcTWHCyx_pN0qYE4qXWYw/featured',
    },
  ],
]

const FooterLinks = () => {
  return (
    <>
      <div className="mt-12 grid w-fit grid-cols-2 gap-6">
        {FOOTER_LINKS.map((links, idx) => (
          <ul key={idx}>
            {links.map(link => (
              <li
                key={link.title}
                className="mb-6 font-body text-sm font-light text-white hover:underline">
                <Link href={link.href}>{link.title}</Link>
              </li>
            ))}
          </ul>
        ))}
      </div>
      <small className="mt-10 font-body text-xs text-white">
        <DisclosureContent abbreviated />
      </small>
    </>
  )
}

const socialContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const socialItem = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

export const Footer = () => {
  const { authenticated } = React.useContext(AuthContext)
  return (
    <footer
      className="relative z-0 w-full overflow-x-hidden bg-mx-gray-dark bg-contain bg-repeat"
      style={{
        backgroundImage: `url(/grains.png)`,
      }}>
      <PartialContainer className="flex flex-col px-4 py-20">
        <Link href="/" className="text-2xl font-bold">
          <Logo className="h-7 w-auto fill-white" />
        </Link>
        {!authenticated && <Newsletter />}
        <FooterLinks />
        <motion.ul
          variants={socialContainer}
          initial="hidden"
          whileInView="show"
          viewport={{
            once: true,
          }}
          className="z-10 mt-10 flex flex-row gap-8 md:gap-10 xl:absolute xl:bottom-1/2 xl:right-20 xl:mt-0 xl:translate-y-1/2 xl:flex-col">
          <motion.li variants={socialItem}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://t.me/+zAcN-iAYAtxkMzgx">
              <Social color="white" type="telegram" />
            </a>
          </motion.li>
          <motion.li variants={socialItem}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://api.whatsapp.com/send?phone=13057474927">
              <Social color="white" type="whatsapp" />
            </a>
          </motion.li>
          <motion.li variants={socialItem}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/MarketXVentures">
              <Social color="white" type="facebook" />
            </a>
          </motion.li>
          <motion.li variants={socialItem}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/MarketXVentures">
              <Social color="white" type="twitter" />
            </a>
          </motion.li>
          <motion.li variants={socialItem}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/marketxventures">
              <Social color="white" type="instagram" />
            </a>
          </motion.li>
          <motion.li variants={socialItem}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/marketx-inc-">
              <Social color="white" type="linkedin" />
            </a>
          </motion.li>
        </motion.ul>
      </PartialContainer>
      <motion.div
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
        }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
        }}
        className="absolute -right-8 bottom-0 -z-10 hidden h-[90%] w-full max-w-[50%] md:block">
        <Image
          fill
          alt="Pattern"
          src={bull}
          className="object-contain object-right-bottom"
        />
      </motion.div>
    </footer>
  )
}
