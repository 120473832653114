import {
  AdminIcon,
  DashboardIcon,
  FundFormationIcon,
  OpportunitiesIcon,
} from './public/images/sidebarIcons'

export interface NestedRoutes {
  path: string
  name: string
  detail?: string
}
export interface Route {
  path: string
  name: string
  icon: any
  layout: string
  permission?: string[]
  relatedPaths?: string[]
  hidden?: boolean
  nestedRoutes?: NestedRoutes[]
}

export function routes(user: any): Route[] {
  return [
    {
      name: 'Invest',
      icon: OpportunitiesIcon,
      layout: '/',
      permission: ['Admins', 'Staff', 'Default'],

      nestedRoutes: [
        {
          path: '/invest/companies',
          name: 'Direct Investments',
          detail: 'Browse, search or filter opportunities.',
        },
        {
          path: '/managedfund',
          name: 'Funds',
          detail: 'Actively managed equity.',
        },
      ],
    },
    {
      name: 'News & Events',
      icon: OpportunitiesIcon,
      layout: '/',
      permission: ['Admins', 'Staff', 'Default'],
      nestedRoutes: [
        {
          path: '/summit',
          name: '2023 Technology & Innovation Summit',
          detail:
            'A global forum to exchange ideas about the most innovative areas of global finance.',
        },
        {
          path: '/opportunity-news',
          name: 'Opportunity news',
          detail: '',
        },
        {
          path: '/news',
          name: 'News',
          detail: 'Stay informed with the latest Market Insights.',
        },
        {
          path: '/market-insights',
          name: 'Market insights',
          detail: '',
        },
        {
          path: '/events',
          name: 'Events',
          detail: 'Connecting a global community of like-minded investors.',
        },
        {
          path: '/conference',
          name: 'Past summits',
          detail: '',
        },
        {
          path: '/investing-101',
          name: 'Investing 101',
          detail: '',
        },
      ],
    },
    {
      path: '/about',
      name: 'About',
      icon: DashboardIcon,
      layout: '/',
      permission: ['Admins', 'Staff', 'Default'],
      nestedRoutes: [
        {
          path: 'https://web.marketxventures.com/about',
          name: 'About Us',
          detail:
            'A global VC firm focused on fintech, deeptech, & vertical SaaS.',
        },
        {
          path: '/team',
          name: 'Team',
          detail: 'Diversity, experience, & networks spanning five continents.',
        },
      ],
    },
    {
      path: '/fundFormation',
      name: 'Fund Formation',
      icon: FundFormationIcon,
      layout: '/',
      permission: ['Admins', 'Staff', 'Default'],
      relatedPaths: ['/spv'],
      hidden: process.env.NEXT_PUBLIC_ENABLE_FUND_FORMATION !== 'true',
    },
    user && {
      path: '/portfolio',
      name: 'My Portfolio',
      icon: DashboardIcon,
      layout: '/',
      permission: ['Admins', 'Staff', 'Default'],
    },
    {
      path: '/admin/deals',
      name: 'Admin',
      icon: AdminIcon,
      layout: '/',
      permission: ['Admins', 'Staff'],
    },
  ]
}

export const adminRoutes: Route[] = [
  {
    path: '/admin/deals',
    name: 'Deals',
    icon: null,
    layout: '/admin',
  },
  {
    path: '/admin/users',
    name: 'Users',
    icon: null,
    layout: '/admin',
  },
  {
    path: '/admin/investments',
    name: 'Investments',
    icon: null,
    layout: '/admin',
  },
  {
    path: '/admin/operations',
    name: 'Operations',
    icon: null,
    layout: '/admin',
  },
  {
    path: '/admin/logs',
    name: 'Logs',
    icon: null,
    layout: '/admin',
  },
  {
    path: '/admin/shareListings',
    name: 'User Share Listings',
    icon: null,
    layout: '/admin',
  },
  {
    path: '/admin/applications',
    name: 'Fund Formation',
    icon: null,
    layout: '/admin',
  },
]
